window.addEventListener("load", function () {
  (function () {
    var copyBtns = document.querySelectorAll(".copy-button"); // Select all buttons

    const copyFormatted = function (event) {
      // Get the clicked button using event.currentTarget
      var button = event.currentTarget;

      // Find the closest `.card-body` and ensure it exists
      var cardBody = button.closest(".card-body");
      if (!cardBody) {
        console.error('Error: Could not find ".card-body" element.');
        return;
      }

      // Find the `.preview` element within the `.card-body` and ensure it exists
      var previewElem = cardBody.querySelector(".preview");
      if (!previewElem) {
        console.error(
          'Error: Could not find ".preview" element inside ".card-body".'
        );
        return;
      }

      var html = previewElem.innerHTML;

      // Check if the Clipboard API is available
      if (navigator.clipboard) {
        // Write the HTML content to the clipboard using the Clipboard API
        navigator.clipboard
          .write([
            new ClipboardItem({
              "text/html": new Blob([html], { type: "text/html" }),
              "text/plain": new Blob([html], { type: "text/plain" }), // Fallback for plain text
            }),
          ])
          .then(function () {
            // Temporarily change the button text to "Copied!"
            button.innerText = "Copied!";
            setTimeout(function () {
              button.innerText = "Copy Signature";
            }, 1750);
          })
          .catch(function (err) {
            console.error("Failed to copy: ", err);
          });
      } else {
        // Fallback if Clipboard API is not supported
        alert("Your browser does not support clipboard API");
      }
    };

    // Add event listener to each button
    copyBtns.forEach(function (btn) {
      btn.addEventListener("click", copyFormatted, false);
    });
  })();
});
